const categories = [
  'music',
  'funny',
  'videos',
  'programming',
  'news',
  'fashion'
];

export default categories;
